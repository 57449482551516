import React from "react";
import clsx from "clsx";
import { Container, Text, Button } from "@atoms";
import { Card } from "@molecules";

import PeopleBottomPattern from "@svg/PeopleBottomPattern";
import PeopleTopPattern from "@svg/PeopleTopPattern";
import useAllResourcesData from "@staticQueries/AllResourcesQuery";

const AdditionalResources = ({ collection }) => {
  const resources = useAllResourcesData();
  const collectionUids = collection.map(c => c.uid);
  const sortedResources = [
    // resources in the same collections
    ...resources.filter(r =>
      r.collection.map(c => collectionUids.includes(c.uid))
    ),
    // remaining resources
    ...resources.filter(r =>
      r.collection.map(c => !collectionUids.includes(c.uid))
    ),
  ];
  return (
    <div className="my-10 md:mt-16">
      <Container smallerPadding>
        <div
          className={clsx(
            "relative overflow-hidden rounded-3xl bg-teal py-10"
          )}
        >
          <Text
            variant="h2"
            className={clsx(
              "relative z-10 mb-8 px-4 text-center text-white sm:px-6"
            )}
          >
            Additional Resources
          </Text>
          <Container className="relative z-10 flex flex-wrap justify-center gap-4 gap-y-8 px-10">
            <ul className="grid grid-cols-1 gap-6 md:grid-cols-3">
              {sortedResources.slice(0, 3).map(r => (
                <li>
                  <Card className="h-full" {...r} />
                </li>
              ))}
            </ul>
          </Container>
          <div className="relative z-10 mt-6 flex w-full shrink-0 flex-wrap justify-center gap-4 px-4 sm:mt-10">
            <Button to="/resources" size="md" color="purple">
              <Text>View All</Text>
            </Button>
          </div>
          <PeopleTopPattern
            className={clsx(
              "absolute -top-16 -left-20 z-0 w-80 text-white opacity-50 md:left-0 md:-top-24 md:w-[28rem]"
            )}
          />
          <PeopleBottomPattern
            className={clsx(
              "absolute -bottom-16 right-0 z-0 w-96 text-white opacity-50 md:-bottom-40 md:w-[40rem]"
            )}
          />
        </div>
      </Container>
    </div>
  );
};

export default AdditionalResources;
